h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  font-weight: 300;
  letter-spacing: var(--letter-spacing-h1);
  color: var(--text-color, var(--navy-blue));
  margin: 0;
}

h1.hero {
  font-size: var(--font-size-hero);
  line-height: var(--line-height-hero);
  font-weight: 300;
  letter-spacing: var(--letter-spacing-hero);
}

h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  font-weight: 300;
  letter-spacing: var(--letter-spacing-h2);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

h3 {
  font-family: var(--font-family);
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-h3);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

h4 {
  font-family: var(--font-family);
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  font-weight: 400;
  letter-spacing: var(--letter-spacing-h4);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

h5 {
  font-family: var(--font-family);
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing-h5);
  font-weight: 600;
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

h6 {
  font-family: var(--font-family);
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-h6);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

html,
body,
div,
span,
p,
td,
li,
address,
strong,
input,
textarea,
button {
  font-family: var(--font-family);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

small {
  font-family: var(--font-family);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: var(--text-color, var(--grey-40));
  margin: 0;
}

a {
  font-family: var(--font-family);
  color: var(--link-color, var(--sky-blue));
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--link-hover-color, var(--navy-blue));
}

a:visited {
  color: var(--link-visited-color, var(--sky-blue));
}
