header {
  padding: 8px;
}

header a {
  display: flex;
  height: 40px;
  width: 100%;
}

header .left-col,
header .right-col {
  flex: 0 0 auto;
}

header .right-col {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

header .logo {
  background-color: var(--pink);
  background-repeat: no-repeat;
  height: 25px;
  mask-image: url('../images/samedi-logo.svg');
  mask-size: cover;
  mask-repeat: no-repeat;
  width: 25px;
}

header .title {
  color: var(--navy-blue);
  font-size: 24px;
  font-weight: bold;
}

header .subtitle {
  color: var(--navy-blue);
  font-size: 11px;
  line-height: 11px;
}

@media (min-width: 768px) {
  header {
    padding: 28px;
  }

  header a {
    height: 58px;
  }

  header .logo {
    height: 44px;
    width: 44px;
  }

  header .title {
    font-size: 32px;
  }

  header .subtitle {
    color: var(--navy-blue);
    font-size: 14px;
    line-height: 14px;
  }
}
