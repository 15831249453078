@font-face {
  font-family: 'source-sans-pro';
  src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'source-sans-pro';
  src: url('../fonts/SourceSansPro-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'source-sans-pro';
  src: url('../fonts/SourceSansPro-Semibold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'source-sans-pro';
  src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}
