:root {
  /* FONTS */
  --font-family: source-sans-pro, Arial, Helvetica, sans-serif;

  /* COLORS */

  /* Modern color palette */
  /* https://www.figma.com/file/imYtJnUeMg03hSYiNpZw4o/Mobile-App?node-id=65%3A670 */
  --white: #fff;
  --dark-blue: #071e3c;
  --light: #f9fafb;
  --mid-blue-900: #003a65;
  --mid-blue-800: #00497f;
  --mid-blue-700: #005898;
  --mid-blue-600: #0067b2; /* primary base color */
  --mid-blue-500: #0085e5;
  --mid-blue-400: #199eff;
  --mid-blue-300: #7fc9ff;
  --mid-blue-200: #b2dfff;
  --mid-blue-150: #cbe9ff;
  --mid-blue-100: #e5f4ff;
  --sky-blue-900: #004762;
  --sky-blue-800: #00597c;
  --sky-blue-700: #007eaf;
  --sky-blue-600: #0091c8;
  --sky-blue-500: #00a3e2; /* primary base color */
  --sky-blue-400: #00b5fb;
  --sky-blue-300: #2fc5ff;
  --sky-blue-200: #7cdaff;
  --sky-blue-150: #afe9ff;
  --sky-blue-100: #e2f7ff;
  --sky-blue-highlight: #47ccff;
  --grey-900: #1b2128;
  --grey-800: #4e6074;
  --grey-700: #8093a9;
  --grey-600: #9eadbd;
  --grey-500: #bcc6d2;
  --grey-400: #cbd4dc;
  --grey-300: #dae0e7;
  --grey-200: #edf0f3;
  --grey-100: #f9fafb;
  --error-900: #62171c;
  --error-800: #771c21;
  --error-700: #8c2027;
  --error-600: #a0252d;
  --error-500: #b52a33; /* primary base color */
  --error-400: #d33f49;
  --error-300: #dc6970;
  --error-200: #eba7ab;
  --error-150: #f4d0d3;
  --error-100: #f8eaeb;
  --warning-900: #5f4b00;
  --warning-800: #785f00;
  --warning-700: #ab8700;
  --warning-600: #deaf00;
  --warning-500: #f8c300; /* primary base color */
  --warning-400: #ffd22c;
  --warning-300: #ffdd5f;
  --warning-200: #ffe892;
  --warning-150: #fff3c5;
  --warning-100: #fffdf8;
  --success-900: #124d2c;
  --success-800: #176237;
  --success-700: #1c7743;
  --success-600: #25a05a;
  --success-500: #2ab566; /* primary base color */
  --success-400: #3fd37f;
  --success-300: #69dc9b;
  --success-200: #92e6b6;
  --success-150: #bcefd2;
  --success-100: #e5f9ee;

  /* alpha colors */
  --white-alpha05: rgba(255, 255, 255, 0.05);
  --white-alpha15: rgba(255, 255, 255, 0.15);

  /* Old color palette */
  --navy-blue: #104080;
  --mid-blue: #0067b2;
  --sky-blue: #00a3e2;
  --light-baby-blue: #cbeef9;
  --orange: #fab710;
  --dark-orange: #e7a606;
  --light-orange: #fdebc4;
  --yellow: #ffce02;
  --pink: #e61657;
  --light-baby-pink: #fbb9b9;
  --grey-30: #333;
  --grey-35: #4d4d4d;
  --grey-40: #666;
  --grey-50: #808080;
  --grey-60: #999;
  --grey-65: #a6a6a6;
  --grey-70: #b3b3b3;
  --grey-80: #ccc;
  --grey-85: #d9d9d9;
  --grey-90: #e6e6e6;
  --grey-95: #f2f2f2;
  --grey-96: #f5f5f5;
  --grey-98: #fafafa;
  --grey-99: #fcfcfc;
  --transparent-button: rgba(255, 255, 255, 0.03);
  --transparent-black: rgba(0, 0, 0, 0.6);
  --transparent-black-darker: rgba(0, 0, 0, 0.75);

  /* WIDTHS */
  --content-width: 1170px;

  /* HEIGHTS */
  --main-footer-height: 32px;

  /* MARGINS */
  --grid-margin: 40px;

  /* PADDINGS */
  --container-padding: 24px 0 48px;

  /* SHADOWS */
  --video-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  --controls-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  --call-controls-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --panel-shadow: 0px 20px 30px -16px rgba(0, 0, 0, 0.25);

  /* GRID */
  --column-width: 100px;
  --max-content-width: 1172px;

  /* DYNAMIC (COMPONENT) FONT SIZES, LINE HEIGHTS & LETTER SPACING */
  --font-size-hero: 30px;
  --line-height-hero: 32px;
  --letter-spacing-hero: -0.01em;
  --font-size-h1: 28px;
  --line-height-h1: 32px;
  --letter-spacing-h1: -0.01em;
  --font-size-h2: 24px;
  --line-height-h2: 32px;
  --letter-spacing-h2: -0.01em;
  --font-size-h3: 22px;
  --line-height-h3: 24px;
  --letter-spacing-h3: -0.01em;
  --font-size-h4: 19px;
  --line-height-h4: 24px;
  --letter-spacing-h4: -0.01em;
  --font-size-h5: 18px;
  --line-height-h5: 24px;
  --letter-spacing-h5: -0.01em;
  --font-size-h6: 12px;
  --line-height-h6: 16px;
  --letter-spacing-h6: -0.01em;
  --font-size-button: 14px;
  --line-height-button: 16px;

  /* STATIC FONT SIZES & LINE HEIGHTS */
  --font-size-tiny: 11px;
  --line-height-tiny: 16px;
  --font-size-secondary: 13px;
  --font-size-small: 14px;
  --line-height-secondary: 16px;
  --font-size-default: 16px;
  --font-size-normal: 18px;
  --line-height-default: 24px;
  --font-size-medium: 21px;
  --line-height-medium: 28px;
  --icon-size-default: 16px;
  --font-size-large: 24px;

  /* SIDE-CONTENT DIMENSIONS (e.g. chat) */
  --side-content-width: 100%;
}

@media (min-width: 768px) {
  :root {
    --side-content-width: 375px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  :root {
    --font-size-hero: 48px;
    --line-height-hero: 52px;
    --font-size-h1: 40px;
    --line-height-h1: 48px;
    --font-size-h2: 32px;
    --line-height-h2: 40px;
    --font-size-h3: 24px;
    --line-height-h3: 32px;
    --letter-spacing-h3: -0.02em;
    --font-size-h4: 20px;
    --line-height-h4: 24px;
    --letter-spacing-h4: -0.02em;
    --container-padding: 24px 0 64px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size-hero: 67px;
    --line-height-hero: 72px;
    --letter-spacing-hero: -0.03em;
    --font-size-h1: 64px;
    --line-height-h1: 72px;
    --letter-spacing-h1: -0.03em;
    --font-size-h2: 40px;
    --line-height-h2: 48px;
    --letter-spacing-h2: -0.03em;
    --font-size-h3: 32px;
    --line-height-h3: 40px;
    --letter-spacing-h3: -0.03em;
    --font-size-h4: 24px;
    --line-height-h4: 32px;
    --container-padding: 24px 0 64px;
  }
}
