.flex-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.flex-container .flex-item {
  flex: 1 0 0;
}

@media (min-width: 768px) {
  .flex-container {
    flex-direction: row;
    max-width: var(--max-content-width);
  }

  .flex-container .flex-item:nth-child(1) {
    max-width: calc(var(--column-width) * 4);
  }

  .flex-container .flex-item:nth-child(2) {
    max-width: calc(var(--column-width) * 8);
  }
}
