/* Please keep alphabetical order */

.calendar-times-light-icon {
  mask-image: url('../images/fontawesome-icons/calendar-times-light.svg');
}

.check-light-icon {
  mask-image: url('../images/fontawesome-icons/check-light.svg');
}

.copy-light-icon {
  mask-image: url('../images/fontawesome-icons/copy-light.svg');
}

.desktop-light-icon {
  mask-image: url('../images/fontawesome-icons/desktop-light.svg');
}

.exclamation-circle-light-icon {
  mask-image: url('../images/fontawesome-icons/exclamation-circle-light.svg');
}

.hourglass-end-light-icon {
  mask-image: url('../images/fontawesome-icons/hourglass-end-light.svg');
}

.laptop-light-icon {
  mask-image: url('../images/fontawesome-icons/laptop-light.svg');
}

.mobile-light-icon {
  mask-image: url('../images/fontawesome-icons/mobile-light.svg');
}

.tablet-light-icon {
  mask-image: url('../images/fontawesome-icons/tablet-light.svg');
}

.times-light-icon {
  mask-image: url('../images/fontawesome-icons/times-light.svg');
}
