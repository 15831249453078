.main-footer {
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  border-top: solid 1px var(--grey-90);
  display: flex;
  flex: 0 0 auto;
  height: var(--main-footer-height);
  justify-content: flex-start;
  max-height: var(--main-footer-height);
  padding: 0 8px;
}

.main-footer a {
  box-sizing: border-box;
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  padding: 0 8px;
}

a.datenschutz-cert-link {
  background-image: url('../images/ips-datenschutz-logo.svg');
  background-repeat: no-repeat;
  color: transparent;
  font-size: 1px; /* to hide text but still screen-readable */
  height: 26px;
  width: 26px;
}

a.samedi-footer-link {
  background-color: var(--grey-50);
  background-repeat: no-repeat;
  color: transparent;
  font-size: 1px; /* to hide text but still screen-readable */
  height: 16px;
  margin-left: auto; /* push to the right end */
  mask-image: url('../images/samedi-footer-logo.svg');
  mask-size: cover;
  mask-repeat: no-repeat;
  width: 16px;
}

a.samedi-footer-link:hover {
  background-color: var(--pink);
}

.main-footer .locale-selector {
  display: flex;
  margin-left: 8px;
}

.main-footer .locale-selector a {
  border-right: solid 1px var(--grey-90);
}

.main-footer .locale-selector a.current-locale {
  color: var(--grey-30);
  cursor: default;
}

.main-footer .locale-selector a:last-child {
  border-right: none;
  padding-right: 0;
}

@media (min-width: 768px) {
  a.samedi-footer-link {
    width: 64px;
  }

  .main-footer .locale-selector {
    margin-left: 24px;
  }
}
